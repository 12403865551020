import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

import { getERC721Contract } from '@src/contracts';
import { cancellablePromise } from '@src/utils/promise';

const getData = async (token: Contract) => {
    const [total_supply, name] = await Promise.all([
        token.totalSupply(),
        token.name(),
    ]);
    return {
        total_supply,
        name,
    };
};

interface NFTDetails {
    name: string;
    total_supply: BigNumber;
    nft_loading: boolean;
}

export const useNFT = (address: string): NFTDetails => {
    const [name, setName] = useState('');
    const [nft_loading, setNFTLoading] = useState(false);
    const [total_supply, setTotalSupply] = useState(BigNumber.from(0));

    useEffect(() => {
        const token = getERC721Contract(address);
        if (token) {
            setNFTLoading(true);
            const { promise, cancel } = cancellablePromise(getData(token));

            promise
                .then(
                    ({
                        total_supply,
                        name,
                    }: {
                        total_supply: BigNumber;
                        name: string;
                    }) => {
                        setTotalSupply(total_supply);
                        setName(name);
                        setNFTLoading(false);
                    },
                )
                .catch(() => {
                    setName('');
                    setTotalSupply(BigNumber.from(0));
                    setNFTLoading(false);
                });

            return cancel;
        } else {
            setTotalSupply(BigNumber.from(0));
            setName('');
        }
    }, [address]);

    return { name, total_supply, nft_loading };
};
