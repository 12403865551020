import dayjs from 'dayjs';

import { ContractType } from '@src/ts/constants';
import {
    CompoundStakingPool,
    LiquidityStakingPool,
    NFTStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { LOGO_STORE } from '@src/config';
import { contract_types } from '@src/constants';

import { getContractInterface } from '../../contracts';

import { multiCall } from '../multicall';

export const mapPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
): Promise<StakingPool> => {
    switch (p.type) {
        case StakingType.NFT:
            return mapNFTPool(p, id);
        case StakingType.Liquidity:
            return mapLiquidityPool(p, id);
        default:
            return mapCompoundPool(p, id);
    }
};

const mapCompoundPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
): Promise<CompoundStakingPool> => {
    const { contract_idx, type, address, vault } = p;
    const token = p.token.toLowerCase();

    const erc20_iface = getContractInterface(ContractType.ERC20);
    const staking_iface = getContractInterface(contract_types[type]);
    const logostore_iface = getContractInterface(ContractType.LogoStore);

    const [[input_symbol], [input_decimals], nft, [logo]] = await multiCall([
        {
            target: token,
            func_name: 'symbol',
            iface: erc20_iface,
        },
        {
            target: token,
            func_name: 'decimals',
            iface: erc20_iface,
        },
        {
            target: address,
            func_name: 'nftInfo',
            iface: staking_iface,
            params: [contract_idx],
        },
        {
            target: LOGO_STORE,
            func_name: 'logoSource',
            iface: logostore_iface,
            params: [token],
        },
    ]);

    const t = {
        address: token,
        decimals: input_decimals,
        symbol: input_symbol,
        logo,
    };

    return {
        name: `${t.symbol} Compounding`,
        id,
        contract_idx,
        type,
        reward_rate: Math.round((Number(p.apy) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: t,
        reward_token: t,
        total_staked: p.totalDeposit?.toString(),
        hardcap: p.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.endDate.mul(1000).toNumber())
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft?.multiplier?.toString() || '10',
        nft_multiplier_used: nft?.active || false,
        nft_name: nft.name,
        address,
        vault,
        is_withdraw_locked: true,
    };
};

const mapNFTPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
): Promise<NFTStakingPool> => {
    const { contract_idx, type, address } = p;
    const nft = p.common.input.toLowerCase();
    const reward = p.common.reward.toLowerCase();

    const [
        [reward_symbol],
        [reward_decimals],
        [reward_logo],
        [nft_logo],
        [nft_name],
        [nft_symbol],
    ] = await multiCall(getNFTCalls(nft, reward));

    const [input_token, reward_token] = [
        {
            address: nft,
            symbol: nft_symbol,
            name: nft_name,
            logo: nft_logo,
        },
        {
            address: reward,
            decimals: reward_decimals,
            symbol: reward_symbol,
            logo: reward_logo,
        },
    ];

    return {
        name: p.name,
        id,
        contract_idx,
        type,
        reward_rate: p.common.rewardRate.toString(),
        lock_period: p.common.lockPeriodInDays?.toString(),
        input_token,
        reward_token,
        total_staked: p.common.totalInvested?.toString(),
        hardcap: p.common.hardCap?.toString() || '1000000000000000000000000000',
        end_date: dayjs(p.common.endDate * 1000)
            .subtract(p.common.lockPeriodInDays, 'days')
            .toISOString(),
        nft_multiplier: 10,
        nft_multiplier_used: false,
        nft_name: '',
        address,
        max_per_user: p.maxPerUser,
        collection_start: p.startIdx,
        collection_end: p.endIdx,
        logo: p.logo,
        header_logo: p.headerLogo,
        staked_tokens: [],
        available_tokens: [],
        is_withdraw_locked: p.common.isWithdrawLocked,
        collection_url: p.collection,
    };
};

const mapLiquidityPool = async (
    // eslint-disable-next-line
    p: any,
    id: number,
): Promise<LiquidityStakingPool> => {
    const { contract_idx, type, address } = p;

    const erc20_iface = getContractInterface(ContractType.ERC20);
    const staking_iface = getContractInterface(contract_types[type]);
    const logostore_iface = getContractInterface(ContractType.LogoStore);
    const pair_iface = getContractInterface(ContractType.Pair);

    const pair_addr = p.input;
    const reward_token = p.reward;

    const [
        is_native,
        nft,
        [token0_addr],
        [token1_addr],
        reserves,
        [total_lps],
    ] = await multiCall([
        {
            target: address,
            func_name: 'isWrappedNative',
            iface: staking_iface,
            params: [pair_addr],
        },
        {
            target: address,
            func_name: 'multis',
            iface: staking_iface,
            params: [contract_idx],
        },
        {
            target: pair_addr,
            func_name: 'token0',
            iface: pair_iface,
        },
        {
            target: pair_addr,
            func_name: 'token1',
            iface: pair_iface,
        },
        {
            target: pair_addr,
            func_name: 'getReserves',
            iface: pair_iface,
        },
        {
            target: pair_addr,
            func_name: 'totalSupply',
            iface: pair_iface,
        },
    ]);

    const [
        [token0_symbol],
        [token0_decimals],
        [token0_logo],
        [token1_symbol],
        [token1_decimals],
        [token1_logo],
        [reward_symbol],
        [reward_decimals],
        [reward_logo],
    ] = await multiCall([
        {
            target: token0_addr,
            func_name: 'symbol',
            iface: erc20_iface,
        },
        {
            target: token0_addr,
            func_name: 'decimals',
            iface: erc20_iface,
        },
        {
            target: LOGO_STORE,
            params: [token0_addr],
            func_name: 'logoSource',
            iface: logostore_iface,
        },
        {
            target: token1_addr,
            func_name: 'symbol',
            iface: erc20_iface,
        },
        {
            target: token1_addr,
            func_name: 'decimals',
            iface: erc20_iface,
        },
        {
            target: LOGO_STORE,
            params: [token1_addr],
            func_name: 'logoSource',
            iface: logostore_iface,
        },
        {
            target: reward_token,
            func_name: 'symbol',
            iface: erc20_iface,
        },
        {
            target: reward_token,
            func_name: 'decimals',
            iface: erc20_iface,
        },
        {
            target: LOGO_STORE,
            params: [reward_token],
            func_name: 'logoSource',
            iface: logostore_iface,
        },
    ]);

    const token0_amount = reserves.reserve0.mul(p.totalInvested).div(total_lps);
    const token1_amount = reserves.reserve1.mul(p.totalInvested).div(total_lps);

    return {
        name: `${token0_symbol} / ${token1_symbol} -> ${reward_symbol}`,
        id,
        contract_idx,
        type,
        reward_rate: Math.round((Number(p.rewardRate) / 10) * 100) / 100 + '%',
        lock_period: p.lockPeriodInDays?.toString(),
        input_token: {
            address: token0_addr,
            decimals: token0_decimals,
            symbol: token0_symbol,
            logo: token0_logo,
        },
        second_input_token: {
            address: token1_addr,
            decimals: token1_decimals,
            symbol: token1_symbol,
            logo: token1_logo,
        },
        reward_token: {
            address: reward_token,
            decimals: reward_decimals,
            symbol: reward_symbol,
            logo: reward_logo,
        },
        total_staked: token0_amount.toString(),
        second_total_staked: token1_amount.toString(),
        hardcap: '0',
        end_date: dayjs(p.endDate * 1000)
            .subtract(Number(p.lockPeriodInDays), 'days')
            .toISOString(),
        nft_multiplier: nft.multi?.toString(),
        nft_multiplier_used: nft.active,
        nft_name: nft.name,
        address,
        is_withdraw_locked: p.isWithdrawLocked,
        is_native: [is_native.pos !== 2, is_native.pos],
        pair: pair_addr,
    };
};

const getNFTCalls = (nft: string, reward_token: string) => {
    const erc20_iface = getContractInterface(ContractType.ERC20);
    const erc721_iface = getContractInterface(ContractType.ERC721);
    const logostore_iface = getContractInterface(ContractType.LogoStore);
    return [
        {
            target: reward_token,
            func_name: 'symbol',
            iface: erc20_iface,
        },
        {
            target: reward_token,
            func_name: 'decimals',
            iface: erc20_iface,
        },
        {
            target: LOGO_STORE,
            func_name: 'logoSource',
            iface: logostore_iface,
            params: [reward_token],
        },
        {
            target: LOGO_STORE,
            func_name: 'logoSource',
            iface: logostore_iface,
            params: [nft],
        },
        {
            target: nft,
            func_name: 'name',
            iface: erc721_iface,
            params: [],
        },
        {
            target: nft,
            func_name: 'symbol',
            iface: erc721_iface,
            params: [],
        },
    ];
};
