import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { getContractByAddress } from '@src/contracts';
import { Reserves } from '@src/ts/interfaces';
import { ContractType } from '@src/ts/constants';

export const usePairReserves = (address: string): Reserves => {
    const contract =
        address && getContractByAddress(address, ContractType.Pair);
    const [reserves, setReserves] = useState({
        reserve0: BigNumber.from(0),
        reserve1: BigNumber.from(0),
    });
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (contract) {
            if (initial) {
                contract.getReserves().then((r) => setReserves(r));
                setInitial(false);
            } else {
                const handler = setTimeout(
                    () => contract.getReserves().then((r) => setReserves(r)),
                    5000,
                );
                return () => clearTimeout(handler);
            }
        }
    }, [reserves, contract, initial]);

    return reserves;
};
