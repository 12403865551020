import { useMemo } from 'react';
import { Contract, ContractInterface } from '@ethersproject/contracts';

import { useWeb3Onboard } from '../useWeb3Onboard';

import { contract } from '@src/contracts';
import { ContractType } from '@src/ts/constants';
import { Web3Provider } from '@ethersproject/providers';
import { isAddress } from '@ethersproject/address';

const c = (
    address: string,
    ABI: ContractInterface,
    provider: Web3Provider,
    withSigner: boolean,
    account: string,
) => {
    return !!address && !!ABI && !!provider && isAddress(address)
        ? new Contract(
              address,
              ABI,
              withSigner
                  ? provider.getSigner(account).connectUnchecked()
                  : provider,
          )
        : undefined;
};

export const useContract = (
    address: string,
    ABI: ContractInterface,
    withSigner = false,
): Contract => {
    const { provider, account } = useWeb3Onboard();

    return useMemo(
        () => c(address, ABI, provider, withSigner, account),
        [address, ABI, withSigner, provider, account],
    );
};

export const useDecubateContract = (
    type: ContractType,
    withSigner = false,
): [Contract, string] => {
    const { address, abi: ABI } = contract[type];
    return [useContract(address, ABI, withSigner), address];
};

export const useDecubateContractByAddress = (
    address: string,
    type: ContractType,
    withSigner = false,
): [Contract, string] => {
    return [useContract(address, contract[type]?.abi, withSigner), address];
};

export const useERC20Contract = (
    address: string,
    withSigner = false,
): Contract => {
    const ABI = contract.ERC20.abi;
    return useContract(address, ABI, withSigner);
};

export const useERC721Contract = (
    address: string,
    withSigner = false,
): Contract => {
    const ABI = contract.ERC721.abi;
    return useContract(address, ABI, withSigner);
};
