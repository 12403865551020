import { useState } from 'react';

import { ModalProps } from '@src/ts/props';

import { Button } from '../Button';

export const Modal: React.FC<ModalProps> = ({
    onClose,
    callback,
    children,
    button_text = 'Close',
}) => {
    const [inside_card, setInsideCard] = useState(false);

    return (
        <div
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover text-white border border-custom"
            id="modal-id"
            onClick={() => !inside_card && onClose()}
        >
            <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div
                data-testid="modal-div"
                className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-level-one"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
            >
                <div>
                    {children}
                    <div className="py-3  mt-2 text-center space-x-4 md:block">
                        <Button
                            className="w-full"
                            onClick={() => {
                                if (callback) callback();
                                onClose();
                            }}
                        >
                            {button_text}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ImageModal } from './ImageModal';
