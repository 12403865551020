import { useState, useEffect, useMemo } from 'react';

import { getERC20Contract } from '@src/contracts';
import { cancellablePromise } from '@src/utils/promise';
import { Contract } from '@ethersproject/contracts';

const getData = async (token: Contract) => {
    const [symbol, decimals] = await Promise.all([
        token.symbol(),
        token.decimals(),
    ]);
    return { symbol, decimals };
};

interface TokenDetails {
    symbol: string;
    decimals: number;
}

export const useToken = (address: string): TokenDetails => {
    const [symbol, setSymbol] = useState('');
    const [decimals, setDecimals] = useState(18);
    const [tried, setTried] = useState(false);
    const [prev_address, setPrevAddress] = useState(address);
    const token = useMemo(() => getERC20Contract(address), [address]);

    useEffect(() => {
        if (!tried && token) {
            const p = getData(token);
            const { cancel, promise } = cancellablePromise(p);

            promise
                .then(({ symbol, decimals }: TokenDetails) => {
                    setSymbol(symbol);
                    setDecimals(decimals);
                    setTried(true);
                })
                .catch(() => {
                    setTried(true);
                });

            return cancel;
        } else if (prev_address !== address) {
            setTried(false);
            setSymbol('');
            setDecimals(18);
        }
        setPrevAddress(address);
    }, [address, token]);

    return { symbol, decimals };
};
