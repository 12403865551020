import { BigNumber } from '@ethersproject/bignumber';
import { cancellablePromise } from '@src/utils/promise';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useERC20Contract, useERC721Contract } from '..';

const MAX_APPROVE_AMOUNT =
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const useTokenApproval = (
    address: string,
    approver: string,
    approvee: string,
    approve_amount: string,
): {
    approved: boolean;
    loading: boolean;
    handleApprove: () => Promise<void>;
} => {
    const [approved, setHasApproval] = useState(false);
    const [loading, setLoading] = useState(false);

    const token = useERC20Contract(address, true);

    useEffect(() => {
        if (token) {
            const p = token
                .allowance(approver, approvee)
                .then((amount: BigNumber) => {
                    return approve_amount === '0'
                        ? amount.gt(0)
                        : amount.gte(approve_amount);
                });

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((approved: boolean) => setHasApproval(approved))
                .catch(() => true);

            return cancel;
        }
    }, [approve_amount, approver, approvee]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');
            const approve_tx = await token.approve(
                approvee,
                MAX_APPROVE_AMOUNT,
            );
            await approve_tx.wait();
            toast.success('Transaction successfully complete');
            setHasApproval(true);
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    return { approved, loading, handleApprove };
};

export const useNFTApproval = (
    address: string,
    approver: string,
    approvee: string,
): {
    approved: boolean;
    loading: boolean;
    handleApprove: () => Promise<void>;
} => {
    const [approved, setHasApproval] = useState(false);
    const [loading, setLoading] = useState(false);

    const token = useERC721Contract(address, true);

    useEffect(() => {
        if (token) {
            const p = token.isApprovedForAll(approver, approvee);

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((approved: boolean) => setHasApproval(approved))
                .catch(() => true);

            return cancel;
        }
    }, [approver, approvee]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');
            const approve_tx = await token.setApprovalForAll(approvee, true);
            await approve_tx.wait();
            toast.success('Transaction successfully complete');
            setHasApproval(true);
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    return { approved, loading, handleApprove };
};
