import { useEffect, useState } from 'react';

import { StakingPool } from '@src/ts/interfaces';
import { useAppDispatch } from '@src/hooks';
import { setPools, updatePools } from '@src/features/staking';
import { useWeb3Onboard } from '../useWeb3Onboard';
import { getStakingPools } from '@src/utils/getters';
import { STAKING_MODULES } from '@src/config';

export const useStakingState = (management = false): boolean => {
    const dispatch = useAppDispatch();
    const { account } = useWeb3Onboard();
    const [initial, setInitial] = useState(true);
    const [loading, setLoading] = useState(true);
    // handle account change
    useEffect(() => {
        if (initial) {
            setLoading(true);
            getStakingPools(STAKING_MODULES, management).then(
                (pools: StakingPool[]) => {
                    dispatch(setPools(pools));
                    setLoading(false);
                },
            );
            setInitial(false);
        }

        if (!management && !!account) {
            dispatch(updatePools(account, true));
        }
    }, [account, initial, loading]);

    return loading;
};
