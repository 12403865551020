import { Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import {
    Dictionary,
    NFTStakingPool,
    StakingModule,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { contract_types, MAX_INT } from '@src/constants';
import StakingABI from '@src/contracts/abi/CompoundStaking.json';

import { getContractByAddress } from '../../contracts';

import { PoolForm } from '@src/ts/props';

import {
    getCompoundPoolForEdit,
    getLiquidityPoolForEdit,
    getNFTPoolForEdit,
} from './edit';
import { mapPool } from './list';

const REQUIRES_MAX_TRANSFER =
    StakingABI.find(({ name }) => name === 'set').inputs.length === 7;

export const getPathname = (path: string): string =>
    path.includes('?') ? path.split('?')[0] : path;

export const getStakingPools = async (
    modules: StakingModule[],
    admin = false,
): Promise<StakingPool[]> => {
    const staking_pools = (
        await Promise.all(
            modules.map(async ({ address, type, ...rest }) =>
                (
                    await getContractByAddress(
                        address,
                        contract_types[type],
                    ).getPools()
                ).map((p, contract_idx) => ({
                    ...p,
                    address,
                    contract_idx,
                    type,
                    ...rest,
                })),
            ),
        )
    ).reduce((acc, curr) => [...acc, ...curr], []);

    const mapped = (await Promise.all(
        staking_pools.map((p, idx) => mapPool(p, idx)),
    )) as StakingPool[];

    return mapped.filter((p: NFTStakingPool) =>
        p.type === StakingType.NFT
            ? admin || !(!p.max_per_user || !p.logo || !p.header_logo)
            : admin || !(!p.input_token.logo || !p.reward_token.logo),
    );
};

export const getPoolForEdit = (
    type: StakingType,
    contract: Contract,
    idx: string,
) => {
    switch (type) {
        case StakingType.Compound:
            return getCompoundPoolForEdit(contract, idx);
        case StakingType.NFT:
            return getNFTPoolForEdit(contract, idx);
        case StakingType.Liquidity:
            return getLiquidityPoolForEdit(contract, idx);
    }
};

export const getAddParams = (state: Dictionary, decimals: number) => {
    const {
        lock_period,
        hardcap,
        end_date,
        input_token,
        is_withdraw_locked,
        reward,
        reward_token,
    } = state;
    const end = dayjs(end_date as string)
        .add(Number(lock_period), 'days')
        .unix();
    switch (state.type) {
        case StakingType.Compound:
            return [
                Math.floor(Number(reward) * 10).toString(),
                lock_period,
                end,
                parseUnits(hardcap as string, decimals),
                input_token,
            ];
        case StakingType.NFT:
            return [
                is_withdraw_locked,
                parseUnits(reward as string, decimals),
                lock_period,
                end,
                hardcap,
                input_token,
                reward_token,
            ];
        case StakingType.Liquidity:
            return [
                is_withdraw_locked,
                Math.floor(Number(reward) * 10).toString(),
                lock_period,
                end,
                0,
                input_token,
                reward_token,
            ];
    }
};

export const getSetParams = (
    idx: string,
    state: PoolForm,
    decimals: number,
) => {
    const {
        lock_period,
        hardcap,
        end_date,
        input_token,
        is_withdraw_locked,
        reward,
        reward_token,
    } = state;
    const end = dayjs(end_date as string)
        .add(Number(lock_period), 'days')
        .unix();
    switch (state.type) {
        case StakingType.Compound:
            return [
                idx,
                Math.floor(Number(reward) * 10).toString(),
                lock_period,
                end,
                parseUnits(state['hardcap'], decimals),
                MAX_INT,
                input_token,
            ].filter(
                (_, idx) => idx !== 5 || (idx === 5 && REQUIRES_MAX_TRANSFER),
            );
        case StakingType.NFT:
            return [
                idx,
                is_withdraw_locked,
                parseUnits(reward as string, decimals),
                lock_period,
                end,
                hardcap,
                input_token,
                reward_token,
            ];
        case StakingType.Liquidity:
            return [
                idx,
                is_withdraw_locked,
                Math.floor(Number(reward) * 10).toString(),
                lock_period,
                end,
                0,
                input_token,
                reward_token,
            ];
    }
};
