import { useState, useEffect } from 'react';

import { getERC20Contract } from '@src/contracts';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { provider } from '@src/bootstrap/web3';

export const useTokenBalance = (address: string, is_native = false): string => {
    const [balance, setBalance] = useState('0');
    const { account } = useWeb3Onboard();

    useEffect(() => {
        const token = getERC20Contract(address);
        if (token && account) {
            const fetchBal = async () => {
                const amount = await (is_native
                    ? provider.getBalance(account)
                    : token.balanceOf(account));
                setBalance(amount.toString());
            };
            fetchBal();
            const t = setInterval(fetchBal, 10000);
            return () => clearInterval(t); // clear
        }
    }, [account, address]);

    return balance;
};
